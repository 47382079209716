@media only screen and (min-width: 600px) {
    .dashboardClient{
        position: absolute;
        top:90px;
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .dashboardClient{
        position: absolute;
        top:90px;
    }
}