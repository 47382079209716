@media only screen and (min-width: 360px) {
    .headerPublic {
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 50px;
        text-align: left;
        // border-bottom: 1px solid var(--primary-color);
        background: white;

        .container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            text-align: center;

            .container-logo {
                display: flex;
                text-align: center;

                img {
                    height: 50px;
                }

                img:hover {
                    opacity: 1.5;
                    height: 50px;
                    width: 200px;
                }
            }

            .container-link {
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: right;
                margin: auto 5px;
                padding: 0 5px;


                .language {

                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    border: 1px solid var(--primary-color);
                    border-radius: 5px;
                    height: 30px;
                    margin: auto 8px;

                    .icon-earth {
                        height: 80%;
                        margin: auto 2px;
                        display: flex;
                        text-align: center;
                        color: white;

                        img {
                            margin: auto 0;

                        }
                    }

                    select {
                        margin: auto;
                        height: 80%;
                        border: none;
                        outline: none;
                        text-align: center;
                    }
                }


                .nameShow {
                    margin: auto 2px;
                    font-weight: bold;
                    border: solid 1px var(--primary-color);
                    border-radius: 5px;
                    padding-right: 5px;
                    padding-left: 5px;
                    padding-top: 2px;
                    padding-bottom: 2px;
                    cursor: pointer;
                    a{
                        color: var(--primary-color);
                    }
                }

                .login {
                    height: 30px;
                    display: flex;
                    background-color: var(--primary-color);
                    border-radius: 5px;
                    margin: auto 4px;

                    a {
                        margin: auto 8px;
                        color: black;
                        text-decoration: none;
                        white-space: nowrap;
                    }
                }

                .cart {
                    padding-right: 20px;

                    span {
                        position: absolute;
                        border: 1px solid var(--primary-color);
                        border-radius: 50%;
                        height: 20px;
                        width: 20px;
                        font-size: 12px;
                        text-align: center;
                        background-color: var(--primary-color);
                        color: white;
                        font-weight: bold;
                    }

                    .icon-cart:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .cardPreviewHeader {


        .container {
            display: flex;
            margin-left: auto;
            width: 100%;
            max-width: 360px;
        }
    }
}


@media only screen and (max-width: 360px) {
    .headerPublic {
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        text-align: left;
        // border-bottom: 1px solid var(--primary-color);
        background: white;


        .container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            text-align: center;

            .container-logo {
                display: none;
                text-align: center;

                img {
                    height: 50px;
                }

                img:hover {
                    opacity: 1.5;
                    height: 50px;
                    width: 200px;
                }
            }

            .container-link {
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: right;
                margin: auto 5px;
                padding: 0 5px;


                .language {

                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    border: 1px solid var(--primary-color);
                    border-radius: 5px;
                    height: 30px;
                    margin: auto 8px;

                    .icon-earth {
                        display: none;
                        height: 80%;
                        margin: auto 2px;
                        display: flex;
                        text-align: center;
                        color: white;

                        img {
                            display: none;
                            margin: auto 0;

                        }
                    }

                    select {
                        margin: auto;
                        height: 80%;
                        border: none;
                        outline: none;
                        text-align: center;
                    }
                }


                .nameShow {
                    margin: auto 2px;
                    font-weight: bold;
                    border: solid 1px var(--primary-color);
                    border-radius: 5px;
                    padding-right: 5px;
                    padding-left: 5px;
                    padding-top: 2px;
                    padding-bottom: 2px;
                    cursor: pointer;
                    a{
                        color: var(--primary-color);
                    }
                }


                .login {
                    height: 30px;
                    display: flex;
                    background-color: var(--primary-color);
                    border-radius: 5px;
                    margin: auto 10px;

                    a {
                        margin: auto 2px;
                        color: black;
                        text-decoration: none;
                        white-space: nowrap;
                    }
                }

                .cart {
                    padding-right: 20px;

                    span {
                        position: absolute;
                        border: 1px solid var(--primary-color);
                        border-radius: 50%;
                        height: 20px;
                        width: 20px;
                        font-size: 12px;
                        text-align: center;
                        background-color: var(--primary-color);
                        color: white;
                        font-weight: bold;
                    }

                    .icon-cart:hover {
                        cursor: pointer;
                    }
                }

            }
        }

    }


    .cardPreviewHeader {


        .container {
            display: flex;
            margin-left: auto;
            width: 100%;
            max-width: 360px;
        }
    }

}









// .headerPublic {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 76px;
//     text-align: left;
//     padding: 10px 24px 10px 24px;


//     .container {
//         display: flex;
//         flex-direction: row;
//         justify-content: space-between;
//         text-align: left;
//         font-size: bold;

//         .left {
//             .logo {
//                 margin: auto 0;
//                 width: 160px;

//                 img {
//                     height: 50px;
//                     width: 50px;
//                 }
//             }
//         }

//         .right {
//             width: calc(100% - 160px);
//             display: flex;
//             flex-direction: row;
//             justify-content: right;
//             margin: auto 0;

//             .language {
//                 display: inline-flex;
//                 color: black;
//                 padding-right: 20px;

//                 .icon-earth {
//                     padding-right: 5px;
//                     color: white;
//                     display: inline-flex;
//                     position: relative;
//                 }

//                 select {
//                     display: inline-flex;
//                     position: relative;
//                     background-color: transparent;
//                     border: 1px solid black;
//                 }

//                 .icon-arrow {
//                     padding-right: 5px;
//                     display: inline-flex;
//                     position: relative;

//                 }
//             }

//             .signin {
//                 height: fit-content;
//                 border: 1px solid black;
//                 border-radius: 10%;

//                 a{
//                     color: black;
//                     text-decoration: none;
//                 }
//             }
//         }


//     }
// }

// @media only screen and (min-width: 600px) {
//     .headerPublic {
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 76px;
//         text-align: left;
//         padding: 10px 24px 10px 24px;


//         .container {
//             display: flex;
//             flex-direction: row;
//             justify-content: space-between;
//             text-align: left;
//             font-size: bold;

//             .left {
//                 .logo {
//                     margin: auto 0;
//                     width: 160px;

//                     img {
//                         height: 50px;
//                         width: 50px;
//                     }
//                 }
//             }

//             .right {
//                 width: calc(100% - 160px);
//                 display: flex;
//                 flex-direction: row;
//                 justify-content: right;
//                 margin: auto 0;

//                 .language {
//                     display: inline-flex;
//                     color: black;
//                     padding-right: 20px;

//                     .icon-earth {
//                         padding-right: 5px;
//                         color: white;
//                         display: inline-flex;
//                         position: relative;
//                     }

//                     select {
//                         display: inline-flex;
//                         position: relative;
//                         background-color: transparent;
//                         border: 1px solid black;
//                     }

//                     .icon-arrow {
//                         padding-right: 5px;
//                         display: inline-flex;
//                         position: relative;

//                     }
//                 }

//                 .signin {
//                     height: fit-content;
//                     border: 1px solid black;
//                     border-radius: 10%;

//                     a{
//                         color: black;
//                         text-decoration: none;
//                     }
//                 }
//             }


//         }
//     }
// }

// @media only screen and (max-width: 600px) {
//     .headerPublic {
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 76px;
//         text-align: left;
//         padding: 10px 24px 10px 24px;


//         .container {
//             display: flex;
//             flex-direction: row;
//             justify-content: space-between;
//             text-align: left;
//             font-size: bold;

//             .left {
//                 .logo {
//                     margin: auto 0;
//                     width: 160px;

//                     img {
//                         height: 50px;
//                         width: 50px;
//                     }
//                 }
//             }

//             .right {
//                 width: calc(100% - 160px);
//                 display: flex;
//                 flex-direction: row;
//                 justify-content: right;
//                 margin: auto 0;

//                 .language {
//                     display: inline-flex;
//                     color: black;
//                     padding-right: 20px;

//                     .icon-earth {
//                         padding-right: 5px;
//                         color: white;
//                         display: inline-flex;
//                         position: relative;
//                     }

//                     select {
//                         display: none;
//                         position: relative;
//                         background-color: transparent;
//                         border: 1px solid black;
//                         outline: none;
//                         option{
//                             display: block;
//                         }
//                     }

//                     .icon-arrow {
//                         padding-right: 5px;
//                         display: inline-flex;
//                         position: relative;

//                     }
//                 }

//                 .signin {
//                     height: fit-content;
//                     border: 1px solid black;
//                     border-radius: 10%;

//                     a{
//                         color: black;
//                         text-decoration: none;
//                     }
//                 }
//             }


//         }
//     }
// }