.checkOutPayment {
    display: flex;
    flex-direction: column;
    text-align: right;

    h3 {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        // font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        background-color: var(--primary-color); // antiquewhite;
        color: white;
        width: fit-content;
        margin: 0 auto;
        padding: 5px 20px;
        border-radius: 5px;
        width: 100%;
        opacity: 0.8;
    }

    .cardCheckOut {
        width: 100%;
        .container {
            margin: 0 auto;
        }
    }

    .checkOutHome {
        display: inline-flex;
        color: var(--primary-color);
        margin: auto 0 0 auto;

        a {
            cursor: pointer;
            color: var(--primary-color);
            font-weight: bold;
            font-size: 16px;
        }
    }

}

.accessPayment {
    text-align: center;
    margin: 0 auto;
    min-width: 250px;
    max-width: 340px;
    width: 100%;
    color: white;

    button {
        padding: 10px;
        border-radius: 5px;
        font-weight: bold;
        cursor: pointer;
    }
}

.checkOutPaypal {
    margin: 10px auto;
    min-width: 250px;
    max-width: 340px;
    width: 50%;
}