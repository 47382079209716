.headerClient {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 51px;
    text-align: left;
    border-bottom: 1px solid var(--primary-color);
    background: white;

    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: center;

        .container-logo {
            display: flex;
            text-align: center;

            img {
                height: 50px;
            }

            img:hover {
                opacity: 1.5;
                height: 50px;
                width: 200px;
            }
        }

        .allLink {
            display: flex;
            flex-direction: row;
            justify-content: right;


            .getPrediction {
                font-weight: bold;
                margin: auto 0;

                a {
                    color: black;
                    text-decoration: underline;
                    background-color: var(--primary-color);
                    padding: 8px;
                    border-radius: 5px;
                }
            }

            .otherLink {
                margin: auto 0;

                ul {
                    li {
                        display: inline-block;
                        padding-right: 15px;
                        margin: 5px;
                        list-style: none;

                        a {
                            text-decoration: none;
                            color: black;
                        }

                        a:hover {
                            color: var(--primary-color);
                            text-decoration: underline;
                        }
                    }



                    .logout {
                        font-weight: bold;
                        padding: 2px 5px;
                        border-radius: 5px;

                        a {
                            color: var(--primary-color);
                        }
                    }
                }
            }


            .collapseMenu {
                margin: auto 0;
                padding-left: 20px;

                .iconMenu {}

                .collapseLink {
                    display: flex;
                    flex-direction: column;
                    background-color: rgb(204, 204, 204, 0.3);
                    position: absolute;
                    right: 0;
                    margin-top: 10px;
                    a {
                        margin: 0 auto;
                        padding: 5px 50px;
                        text-decoration: none;
                        color: black;
                        font-weight: bold;
                    }

                    a:hover {
                        cursor: pointer;
                        color: var(--primary-color);
                        text-decoration: underline;
                    }

                    .logout{
                        color: var(--primary-color, 0.3);
                    }
                }
            }

        }
    }
}


@media only screen and (min-width: 600px) {
    .headerClient {
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 51px;
        text-align: left;
        border-bottom: 1px solid var(--primary-color);
        background: white;
    
        .container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            text-align: center;
    
            .container-logo {
                display: flex;
                text-align: center;
    
                img {
                    height: 50px;
                }
    
                img:hover {
                    opacity: 1.5;
                    height: 50px;
                    width: 200px;
                }
            }
    
            .allLink {
                display: flex;
                flex-direction: row;
                justify-content: right;
    
    
                .getPrediction {
                    font-weight: bold;
                    margin: auto 0;
    
                    a {
                        color: black;
                        text-decoration: underline;
                        background-color: var(--primary-color);
                        padding: 8px;
                        border-radius: 5px;
                    }
                }
    
                .otherLink {
                    margin: auto 0;
    
                    ul {
                        li {
                            display: inline-block;
                            padding-right: 15px;
                            margin: 5px;
                            list-style: none;
    
                            a {
                                text-decoration: none;
                                color: black;
                            }
    
                            a:hover {
                                color: var(--primary-color);
                                text-decoration: underline;
                            }
                        }
    
    
    
                        .logout {
                            font-weight: bold;
                            padding: 2px 5px;
                            border-radius: 5px;
    
                            a {
                                color: var(--primary-color);
                            }
                        }
                    }
                }
    
    
                .collapseMenu {
                    display: none;
                    margin: auto 0;
                    padding-left: 20px;
    
                    .iconMenu {}
    
                    .collapseLink {
                        display: flex;
                        flex-direction: column;
                        background-color: rgb(204, 204, 204, 0.3);
                        position: absolute;
                        right: 0;
                        margin-top: 10px;
                        a {
                            margin: 0 auto;
                            padding: 5px 50px;
                            text-decoration: none;
                            color: black;
                            font-weight: bold;
                        }
    
                        a:hover {
                            cursor: pointer;
                            color: var(--primary-color);
                            text-decoration: underline;
                        }
    
                        .logout{
                            color: var(--primary-color, 0.3);
                        }
                    }
                }
    
            }
        }
    }
    
}

@media only screen and (max-width: 600px) {
    .headerClient {
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 51px;
        text-align: left;
        border-bottom: 1px solid var(--primary-color);
        background: white;
    
        .container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            text-align: center;
    
            .container-logo {
                display: flex;
                text-align: center;
    
                img {
                    height: 50px;
                }
    
                img:hover {
                    opacity: 1.5;
                    height: 50px;
                    width: 200px;
                }
            }
    
            .allLink {
                display: flex;
                flex-direction: row;
                justify-content: right;
    
    
                .getPrediction {
                    font-weight: bold;
                    margin: auto 0;
    
                    a {
                        color: black;
                        text-decoration: underline;
                        background-color: var(--primary-color);
                        padding: 8px;
                        border-radius: 5px;
                    }
                }
    
                .otherLink {
                    display: none;
                    margin: auto 0;
    
                    ul {
                        li {
                            display: inline-block;
                            padding-right: 15px;
                            margin: 5px;
                            list-style: none;
    
                            a {
                                text-decoration: none;
                                color: black;
                            }
    
                            a:hover {
                                color: var(--primary-color);
                                text-decoration: underline;
                            }
                        }
    
    
    
                        .logout {
                            font-weight: bold;
                            padding: 2px 5px;
                            border-radius: 5px;
    
                            a {
                                color: var(--primary-color);
                            }
                        }
                    }
                }
    
    
                .collapseMenu {
                    margin: auto 0;
                    padding-left: 20px;
    
                    .iconMenu {}
    
                    .collapseLink {
                        display: flex;
                        flex-direction: column;
                        background-color: rgb(204, 204, 204, 0.3);
                        position: absolute;
                        right: 0;
                        margin-top: 10px;
                        a {
                            margin: 0 auto;
                            padding: 5px 50px;
                            text-decoration: none;
                            color: black;
                            font-weight: bold;
                        }
    
                        a:hover {
                            cursor: pointer;
                            color: var(--primary-color);
                            text-decoration: underline;
                        }
    
                        .logout{
                            color: var(--primary-color, 0.3);
                        }
                    }
                }
    
            }
        }
    }
    
}