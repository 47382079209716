.forgotPasswordClient{
    
    .wrapper {
        margin: 5% auto;
        display: block;
        min-width: 250px;
        max-width: 500px;
        text-align: center;

        .container {
            border: 1px solid #ccc;
            border-radius: 5px;
            width: 100%;
            padding: 0 0 20px 0;



            .headerLogin {
                color: var(--primary-color);
                font-weight: bold;
                
                p{
                    color: #ccc;
                }
            }

            .formLogin {
                display: flex;
                flex-direction: column;
                text-align: left;
                width: 90%;
                margin: 0 auto;

                .inputForm {
                    padding: 10px;
                    margin-bottom: 10px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    width: 100%;
                }

                .verifyCode{
                    margin-right: 10px;
                    margin: auto 10px auto auto;
                    width: 90%;

                }

                p {
                    color: black;
                    font-weight: normal;
                    text-align: center;

                    a {
                        color: #1877f2;
                        text-decoration: none;
                        margin: 10px 0;
                        font-weight: bold;
                    }

                    a:hover {
                        text-decoration: underline;
                    }
                }

                .formVerifyCode {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    button {
                        background-color: #1877f2;
                        color: white;
                        border: none;
                        border-radius:50%;
                        margin-right: 0;
                    }
                }

                .changePassword {
                    background-color: #42b72a;
                    color: white;
                    border: none;
                    border-radius: 5px;
                    width: 200px;
                    padding: 10px;
                    margin: 20px auto;
                }
            }
        }
    }
}