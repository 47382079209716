.clientProfileCard {
    

    .card {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        max-width: 300px;
        margin: auto;
        text-align: center;
        font-family: arial;
        margin-top: 50px;
        background-color: var(--primary-color);

        p {
            text-align: left;
            padding-left: 10px;
            padding: 5px;
            color: white;
        }

    }

}