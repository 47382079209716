@media only screen and (min-width: 800px) {
    .servicePublic {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 2px solid var(--primary-color);
        border-top: 10px solid var(--primary-color);
        margin: 0;
        padding: 40px 0;


        .titleService {

            h2 {
                font-weight: 900;
                font-style: oblique;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                color: var(--primary-color);
                width: fit-content;
                margin: 0 auto;
                border-radius: 10px;
                padding: 0 10px;
            }

            p {
                color: black
            }
        }

        .selectAlgorithm {

            .wrapper {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                text-align: center;


                .algorithmWrapper {
                    margin: 0 auto;

                    .title {
                        font-weight: bold;
                    }

                    .title:hover {
                        background-color: var(--primary-color);
                        cursor: pointer;
                    }

                    .price {
                        width: fit-content;
                        text-align: right;

                        span {
                            font-weight: bold;
                            font-size: 135%;
                            color: var(--primary-color);
                        }
                    }
                }

                button {
                    padding: 0 10px;
                    margin: 5px 10px;
                    background-color: white;
                    border-radius: 5px;
                    cursor: pointer;
                }

                button:hover {
                    color: var(--primary-color);
                }
            }
        }

    }

}

@media only screen and (max-width: 800px) {
    .servicePublic {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 2px solid var(--primary-color);
        border-top: 10px solid var(--primary-color);
        margin: 0;
        padding: 40px 0;

        .titleService {

            h2 {
                font-weight: 900;
                font-style: oblique;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                color: var(--primary-color);
                width: fit-content;
                margin: 0 auto;
                border-radius: 10px;
                padding: 0 10px;
            }

            p {
                color: black
            }
        }

        .selectAlgorithm {

            .wrapper {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                text-align: center;


                .algorithmWrapper {
                    margin: 0 auto;

                    .title {
                        font-weight: bold;
                    }

                    .title:hover {
                        background-color: var(--primary-color);
                        cursor: pointer;
                    }

                    .price {
                        width: fit-content;
                        text-align: right;

                        span {
                            font-weight: bold;
                            font-size: 135%;
                            color: var(--primary-color);
                        }
                    }
                }

                button {
                    padding: 0 10px;
                    margin: 5px 10px;
                    background-color: white;
                    border-radius: 5px;
                    cursor: pointer;
                }

                button:hover {
                    color: var(--primary-color);
                    font-weight: bold;
                }
            }
        }

    }

}


@media only screen and (max-width: 450px) {
    .servicePublic {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 2px solid var(--primary-color);
        border-top: 10px solid var(--primary-color);
        margin: 0;
        padding: 40px 0;
        //background-color:  rgb(165,175,96); //antiquewhite;


        .titleService {

            h2 {
                font-weight: 900;
                font-style: oblique;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                color: var(--primary-color);
                margin: 0 auto;
                border-radius: 10px;
                padding: 0 10px;
                text-align: center;
            }

            p {
                color: black
            }
        }

        .selectAlgorithm {

            .wrapper {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                text-align: center;


                .algorithmWrapper {
                    margin: 0 auto;

                    .title {
                        font-weight: bold;
                    }

                    .title:hover {
                        background-color: var(--primary-color);
                        cursor: pointer;
                    }

                    .price {
                        width: fit-content;
                        text-align: right;

                        span {
                            font-weight: bold;
                            font-size: 135%;
                            color: var(--primary-color);
                        }
                    }
                }

                button {
                    padding: 0 10px;
                    margin: 5px 10px;
                    background-color: white;
                    border-radius: 5px;
                    cursor: pointer;
                }

                button:hover {
                    color: var(--primary-color);
                    font-weight: bold;
                }
            }
        }

    }

}