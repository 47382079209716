@media only screen and (min-width: 900px) {
    .tableShow {

        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding-bottom: 100px;

        .inputButton {

            .button_input {
                color: white;
                width: fit-content;
                width: 30px;
                height: 30px;
                border: none;
                border-radius: 50%;
                margin: 0 5px;
            }

            #buttonP {
                background-color: var(--blue-color);
            }

            #buttonB {
                background-color: var(--red-color);
            }

            #buttonT {
                background-color: var(--green-color);
            }

            #buttonDelete {
                background-color: black;
                padding: 0;
            }
        }

        .containerTable {
            table {
                grid-template-rows: repeat(6, 1fr);
                height: 200px;
                width: 300px;
                margin: 0 auto;

                tbody {
                    tr {
                        border: 1px solid black;

                        td {
                            border-bottom: 1px solid var(--primary-color);
                            width: 28px;
                            height: 28px;
                            margin: 0;
                            padding: 0;
                            text-align: center;

                            span {
                                color: white;
                                height: 20px;
                                width: 20px;
                                border-radius: 50%;
                                display: inline-block;
                                margin: 0;
                                padding: 0;
                            }
                        }

                    }
                }
            }
        }

    }
}

@media only screen and (max-width: 900px) {
    .tableShow {

        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        .inputButton {

            .button_input {
                color: white;
                width: fit-content;
                width: 30px;
                height: 30px;
                border: none;
                border-radius: 50%;
                margin: 0 5px;
            }

            #buttonP {
                background-color: var(--blue-color);
            }

            #buttonB {
                background-color: var(--red-color);
            }

            #buttonT {
                background-color: var(--green-color);
            }

            #buttonDelete {
                background-color: black;
                padding: 0;
            }
        }

        .containerTable {
            table {
                grid-template-rows: repeat(6, 1fr);
                height: 200px;
                width: 300px;
                margin: 0 auto;

                tbody {
                    tr {
                        border: 1px solid black;

                        td {
                            border-bottom: 1px solid var(--primary-color);
                            width: 28px;
                            height: 28px;
                            margin: 0;
                            padding: 0;
                            text-align: center;

                            span {
                                color: white;
                                height: 20px;
                                width: 20px;
                                border-radius: 50%;
                                display: inline-block;
                                margin: 0;
                                padding: 0;
                            }
                        }

                    }
                }
            }
        }

    }
}

@media only screen and (max-width: 500px) {
    .tableShow {

        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;


        .inputButton {

            .button_input {
                color: white;
                width: fit-content;
                width: 30px;
                height: 30px;
                border: none;
                border-radius: 50%;
                margin: 0 5px;
            }

            #buttonP {
                background-color: var(--blue-color);
            }

            #buttonB {
                background-color: var(--red-color);
            }

            #buttonT {
                background-color: var(--green-color);
            }

            #buttonDelete {
                background-color: black;
                padding: 0;
            }
        }

        .containerTable {
            table {
                grid-template-rows: repeat(6, 1fr);
                height: 200px;
                width: 300px;
                margin: 0 auto;

                tbody {
                    tr {
                        border: 1px solid black;

                        td {
                            border-bottom: 1px solid var(--primary-color);
                            width: 28px;
                            height: 28px;
                            margin: 0;
                            padding: 0;
                            text-align: center;

                            span {
                                color: white;
                                height: 20px;
                                width: 20px;
                                border-radius: 50%;
                                display: inline-block;
                                margin: 0;
                                padding: 0;
                            }
                        }

                    }
                }
            }
        }

    }
}