@media only screen and (min-width: 900px) {
    .TableBoardPublicV2 {

        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding-bottom: 100px;

        p {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 700;
            font-size: 16px;

            span {
                background-color: var(--primary-color); // antiquewhite;
                color: antiquewhite;
                border-radius: 5px;
            }
        }

        .inputButton {

            .button_input {
                color: white;
                width: fit-content;
                width: 30px;
                height: 30px;
                border: none;
                border-radius: 50%;
                margin: 0 5px;
            }

            #buttonP {
                background-color: var(--blue-color);
            }

            #buttonB {
                background-color: var(--red-color);
            }

            #buttonT {
                background-color: var(--green-color);
            }

            #buttonDelete {
                background-color: black;
                padding: 0;
            }
        }

        .containerTable {
            table {
                grid-template-rows: repeat(6, 1fr);
                height: 200px;
                width: 300px;
                margin: 0 auto;

                tbody {
                    tr {
                        border: 1px solid black;

                        td {
                            border-bottom: 1px solid var(--primary-color);
                            width: 28px;
                            height: 28px;
                            margin: 0;
                            padding: 0;
                            text-align: center;

                            span {
                                color: white;
                                height: 20px;
                                width: 20px;
                                border-radius: 50%;
                                display: inline-block;
                                margin: 0;
                                padding: 0;
                            }
                        }

                    }
                }
            }
        }

        .getAlgorithmResult {
            margin: 25px 5px 10px 5px;

            button {
                background-color: white;
                border-radius: 5px;
                padding: 5px;
                cursor: pointer;
            }

            button:hover {
                color: var(--primary-color);
                font-weight: bold;
            }
        }

        .getResult {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            padding: 0 20px;
            width: 100%;
            overflow-wrap: break-word;

            .logicMath {
                max-width: 250px;
                color: var(--blue-color);
            }

            .AI {
                max-width: 250px;
                color: var(--red-color);
            }

            .naturalRandom {
                max-width: 250px;
                color: var(--green-color);
            }

            .fixPattern {
                max-width: 250px;
                color: purple;
            }

            .getResultAdd {
                display: flex;
                flex-direction: column;
                margin: 0 auto;
            }

            .getResultBTag {
                background: var(--primary-color);
                border-radius: 5px;
                color: white;
                width: fit-content;
                margin: 0 auto;
                padding: 0 5px;
                font-weight: normal;
            }

            div.bTag {
                margin: 0 20px;
                font-weight: bold;
                width: 80%;
                word-wrap: break-word;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .TableBoardPublicV2 {

        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        p {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 700;
            font-size: 16px;

            span {
                background-color: var(--primary-color); // antiquewhite;
                color: antiquewhite;
                border-radius: 5px;
            }
        }

        .inputButton {

            .button_input {
                color: white;
                width: fit-content;
                width: 30px;
                height: 30px;
                border: none;
                border-radius: 50%;
                margin: 0 5px;
            }

            #buttonP {
                background-color: var(--blue-color);
            }

            #buttonB {
                background-color: var(--red-color);
            }

            #buttonT {
                background-color: var(--green-color);
            }

            #buttonDelete {
                background-color: black;
                padding: 0;
            }
        }

        .containerTable {
            table {
                grid-template-rows: repeat(6, 1fr);
                height: 200px;
                width: 300px;
                margin: 0 auto;

                tbody {
                    tr {
                        border: 1px solid black;

                        td {
                            border-bottom: 1px solid var(--primary-color);
                            width: 28px;
                            height: 28px;
                            margin: 0;
                            padding: 0;
                            text-align: center;

                            span {
                                color: white;
                                height: 20px;
                                width: 20px;
                                border-radius: 50%;
                                display: inline-block;
                                margin: 0;
                                padding: 0;
                            }
                        }

                    }
                }
            }
        }

        .getAlgorithmResult {
            margin: 25px 0 10px 0;

            button {
                background-color: white;
                border-radius: 5px;
            }

            button:hover {
                color: var(--primary-color);
                font-weight: bold;
            }
        }

        .getResult {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            padding: 0 20px;

            .logicMath {
                max-width: 300px;
                color: var(--blue-color);
            }

            .AI {
                max-width: 300px;
                color: var(--red-color);
            }

            .naturalRandom {
                max-width: 300px;
                color: var(--green-color);
            }

            .fixPattern {
                max-width: 300px;
                color: purple;
            }

            .getResultAdd {
                display: flex;
                flex-direction: column;
                margin: 0 auto;
            }

            .getResultBTag {
                background: var(--primary-color);
                border-radius: 5px;
                color: white;
                width: fit-content;
                margin: 5px auto;
                padding: 0 5px;
                font-weight: normal;
            }

            div.bTag {
                margin: 0 auto;
                width: 80%;
                font-weight: bold;
                word-wrap: break-word;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .TableBoardPublicV2 {

        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        p {
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 700;
            font-size: 16px;

            span {
                background-color: var(--primary-color); // antiquewhite;
                color: antiquewhite;
                border-radius: 5px;
            }
        }

        .inputButton {

            .button_input {
                color: white;
                width: fit-content;
                width: 30px;
                height: 30px;
                border: none;
                border-radius: 50%;
                margin: 0 5px;
            }

            #buttonP {
                background-color: var(--blue-color);
            }

            #buttonB {
                background-color: var(--red-color);
            }

            #buttonT {
                background-color: var(--green-color);
            }

            #buttonDelete {
                background-color: black;
                padding: 0;
            }
        }

        .containerTable {
            table {
                grid-template-rows: repeat(6, 1fr);
                height: 200px;
                width: 300px;
                margin: 0 auto;

                tbody {
                    tr {
                        border: 1px solid black;

                        td {
                            border-bottom: 1px solid var(--primary-color);
                            width: 28px;
                            height: 28px;
                            margin: 0;
                            padding: 0;
                            text-align: center;

                            span {
                                color: white;
                                height: 20px;
                                width: 20px;
                                border-radius: 50%;
                                display: inline-block;
                                margin: 0;
                                padding: 0;
                            }
                        }

                    }
                }
            }
        }

        .getAlgorithmResult {
            margin: 25px 0 10px 0;

            button {
                background-color: white;
                border-radius: 5px;
            }

            button:hover {
                color: var(--primary-color);
                font-weight: bold;
            }
        }

        .getResult {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            padding: 0 20px;

            .logicMath {
                max-width: 300px;
                color: var(--blue-color);
            }

            .AI {
                max-width: 300px;
                color: var(--red-color);
            }

            .naturalRandom {
                max-width: 300px;
                color: var(--green-color);
            }

            .fixPattern {
                max-width: 300px;
                color: purple;
            }
            .getResultAdd {
                display: flex;
                flex-direction: column;
                margin: 0 auto;
            }

            .getResultBTag {
                background: var(--primary-color);
                border-radius: 5px;
                color: white;
                width: fit-content;
                margin: 5px auto;
                padding: 0 5px;
                font-weight: normal;
            }

            div.bTag {
                margin: 0 auto;
                width: 80%;
                font-weight: bold;
                word-wrap: break-word;
            }
        }
    }
}